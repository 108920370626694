export const fetchSuggestions = async (searchKey) => {
    try {
      
  //const response = await fetch(`https://localhost:7100 -- local
  //https://apis.muhireka.com -- production
    const response = await fetch(`https://apis.muhireka.com/api/search/searchoccupation?searchKey=${searchKey}`,{
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    }
   // ,credentials: 'include'
});
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const results = await response.json();
      return results;
    } catch (error) {
      console.error("Error fetching suggestions:", error.message);
      throw error;
    }
    };

    export const fetchProspects = async (searchKey,pageNum) => {
      try {
        const response = await fetch(`https://apis.muhireka.com/api/search/searchprospects?searchOccupation=${searchKey}&pageNum=${pageNum}`,{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
          //.credentials: 'include'
      });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const results = await response.json();
        return results;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
      }

      export const fetchProspectsDirect = async (searchWord,pageNum) => {
        try {
          const response = await fetch(`https://apis.muhireka.com/api/search/searchprospectsdirect?searchOccupation=${searchWord}&pageNum=${pageNum}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
            //.credentials: 'include'
        });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const results = await response.json();
          return results;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
        }

    export const getProspectDetails = async (prospectId) => {
      try {
        const response = await fetch(`https://apis.muhireka.com/api/search/getprospectdetails?prospectId=${prospectId}`,{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
          //,credentials: 'include'
      });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const results = await response.json();
        return results;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    };
 

const PageNumComponent = ({currentPage,handlePageChange,handlelastPageReset,islastPageReach}) => {
    return ( 
        <div>
            <span>Page {currentPage+1}</span>
            <button
            onClick={() => {handlePageChange(currentPage - 1)
                handlelastPageReset(false)}
            }
            >
            Previous
            </button>
            <button
            onClick={() => {
                if(!islastPageReach) handlePageChange(currentPage + 1)
                else handlePageChange(currentPage-1)
            }}
            >
            Next
        </button>
        </div>
     );
}
 
export default PageNumComponent;
import classes from '../Advertisement/RightSide_Home.module.css';
import DataGathering from '../Common/DataGathering';

const RightSide_Home = () => {
    return ( 
        <div className={classes.mainDiv}>
             <DataGathering></DataGathering>
            <span>Featured</span>
            <a href="https://www.freepik.com/free-vector/construction-worker-uniform_27285963.htm#query=construction%20worker%20cartoon&position=2&from_view=keyword&track=ais&uuid=dda1dbca-bbcc-4602-8f43-4c87bccb1292">Image by brgfx</a> on Freepik
        </div>
     );
}
 
export default RightSide_Home;
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Research from "./pages/Research/Research";
import Services from "./pages/Services/Services";
import About from "./pages/About/About";
import MainNavigation from "./components/Navigation/MainNavigation";

function App() {
  return (
    <React.Fragment>
      <MainNavigation />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/Research" element={<Research />} /> */}
        {/* <Route path="/Services" element={<Services />} /> */}
        <Route path="/About" element={<About />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;

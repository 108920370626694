import React from "react";
import { Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import SiteLogo from '../../images/Muhireka.png';
import PhilFlag from '../../images/flag.png';
import HomePic from '../../images/homepic.png';
import AboutPic from '../../images/aboutpic.png';

const MainNavigation = () => {
  return (
    <React.Fragment>
    <header className={classes.header}> 
    <img src={PhilFlag} className={classes.flag} alt='flag'></img>
    <img src={SiteLogo} id='logoname' alt='No logo' />   
      <nav>   
        <ul>          
           <li>
            <Link to="/"><img src={HomePic} className={classes.homepic}/><span className={classes.linkText}>Home</span></Link>
          </li>
          {/* <li>
            <Link to="/Research">Research</Link>
          </li> */}
          {/* <li>
            <Link to="/Services">Services</Link>
          </li> */}
          <li>
            <Link to="/About"><img src={AboutPic} className={classes.aboutpic}/><span className={classes.linkText}>About</span></Link>
          </li>
        </ul>
      </nav>
    </header>
  </React.Fragment>
  );
};

export default MainNavigation;

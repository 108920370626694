import React from 'react';
import classes from '../Search/AutoSuggest.module.css'
const AutoSuggest = ({results, onItemSelect}) => {
    return (  
        <div className={classes.AutoSuggest}>
            <ul>
            {results.map(result => (
          <li key={result.occupationId} onClick={() => onItemSelect(result.occupationName, result.occupationId)}>{result.occupationName}</li>
          // Add other properties as needed
        ))}
            </ul>
        </div>
    );
}
 
export default AutoSuggest;
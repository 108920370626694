import classes from '../About/StoryHolder.module.css';
import Scene1 from "../../images/aboutscenes/Scene1.png"

const StoryHolder = ({imagePath}) => {
    return (  
        <div className={classes.sceneOuterDiv}>
            <img className={classes.sceneImg} src={imagePath} alt="no image" ></img>
        </div>
    );
}
 
export default StoryHolder;
import React,{useState} from "react";
import classes from '../Search/ProspectCardDetail.module.css';

const ProspectCardDetail = ({setOpenModal, prospectData}) => {
  const [buttonText,setButtonText] = useState("Copy Contact#")
  const copyContactNumber = (contactNumber) => {navigator.clipboard.writeText(contactNumber);
    setButtonText("Copied");
  };
    return (  
    <div className={classes.modalBackground}>
      <div className={classes.modalContainer}>
        <div className={classes.title}>
          <h1>Hi! I'm {prospectData.prospectName}.</h1>
        </div>
        <div className={classes.body}>
          <img className={classes.image} src={prospectData.mainPicURL} alt="https://raw.githubusercontent.com/stantheman21ph/TestSampleData/main/Pictures/Nopic.jpg" />
          <div><label>Address: </label><text>{prospectData.location}</text></div>
          <div><label>Contact #: </label><text>{prospectData.contactNumber}</text></div>
          <div><label>Sample Works: </label><a href={prospectData.sampleWorksSite} target="_blank" rel="noreferrer">Click to go to the site</a></div>
        </div>
        <div className={classes.footer}>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Ok
          </button>
          <button onClick={() => {copyContactNumber(prospectData.contactNumber)}}>{buttonText}</button>
        </div>
      </div>
    </div>
    );
}
 
export default ProspectCardDetail;
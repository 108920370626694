import React, { useEffect } from "react";
import ProspectCard from "./ProspectCard";
import classes from "../Search/SearchResults.module.css"

const SearchResults = ({callToggleVisibility, callPressBackInitialize, prospectSearchResults, occupationSearch, occupationSearchDirect}) => {
    console.log("Occupation Search Value:", occupationSearch);
    const goBackSearchPage = () => {
        callPressBackInitialize();
        callToggleVisibility();
    }

    return (
        <React.Fragment>
        <button onClick={goBackSearchPage}>Back</button>
        <br/>
        <div className={classes.occupationDiv}>
        <label className={classes.lblSearchOccupation}>Searched Occupation: </label>
        {
            occupationSearch ? (
                <text className={classes.occupationSearch}>{occupationSearch}</text>
            ) : (
                <text className={classes.occupationSearch}>{occupationSearchDirect}</text>
            )
        }
        </div>
        
        <div className={classes.Container}>           
            {prospectSearchResults.map(prospect => (
                <div className={classes.RightDiv}>
                <ProspectCard prospectItem = {prospect} />
                </div>
            ))}
        </div>

        </React.Fragment>
    );
}
 
export default SearchResults;
import StoryHolder from "../../components/About/StoryHolder";
import classes from "../About/About.module.css"


const About = () => {
  const numberOfScenes = [
    'images/Scene1.png',
    'images/Scene2.png',
    'images/Scene3.png',
    'images/Scene4.png',
    'images/Scene5.png',
    'images/Scene6.png'
  ];

  return (
    <div className={classes.aboutOuterDiv}>
      <h2> Why do you want to use this site?</h2>
      {numberOfScenes.map((scene, index) => (
        <StoryHolder key={index} imagePath={`${process.env.PUBLIC_URL}/${scene}`} />
      ))}
      <h2>This Site is the Answer!</h2>
      <div className={classes.quote}>
        A website dedicated to finding hard-to-find jobs, currently focusing on Cebu.
        <br/>
        This website for one will provide a service to those workers to help them find work.
        <br/>
        But the ultimate goal of this website is to provide a service to those people who would want 
        <br/>
        to get a job done but just don't know whom to call. Sometimes they will just postpone the job 
        <br/>
        or worst just will not go through with it anymore.
        <br/>
        The main advantage of this site is that it helps users easily find the best worker match 
        <br/>
        by providing detailed information about workers.
    </div>
    </div>
  );
};


export default About;
